//---------------------------------------------
// Video Section
//---------------------------------------------
#video-section {
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, auto);
  @screen lg {
    grid-template-rows: 100vh auto;
  }

  video {
    grid-column: 1/2;
    grid-row: 1/2;
  }
  .divider {
    grid-column: 1/2;
    grid-row: 2/3;
    transform: translateY(-12.5%);
  }
}

//---------------------------------------------
// Introduction
//---------------------------------------------
#introduction {
  img {
    @screen lg {
      // margin-left: -45%;
      position: absolute;
      top: 65%;
      left: -40%;
      width: 110%;
      max-width: unset;
    }
  }
}

//---------------------------------------------
// Website Experience
//---------------------------------------------
#website-experience {
  img {
    @screen lg {
      position: absolute;
      clip-path: inset(18% 0 25% 0);
      left: 0;
      top: 25%;
      width: 35%;
    }
    @screen 2xl {
      top: 15%;
    }
  }
}

//---------------------------------------------
// Schedule
//---------------------------------------------
#schedule {
  .timeline-grid {
    @screen md {
      .timestamp:last-child {
        @apply border-r-[1px] border-r-white/50;
      }
    }
  }
}

//---------------------------------------------
// About
//---------------------------------------------
#about {
  @screen lg {
    .about-grid {
      grid-template-columns: 0.6fr 0.4fr;
    }
    .offset-image-1 {
      transform: scale(1.25);
      margin-top: -10%;
      margin-left: -20%;
    }
    .offset-image-2 {
      margin-top: -40%;
      width: 135%;
      max-width: unset;
    }
  }
  @screen 2xl {
    .offset-image-2 {
      top: -60%;
      width: 165%;
    }
  }
}

//---------------------------------------------
// Portfolios grid section
//---------------------------------------------
#portfolios {
  & > .container {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, minmax(min-content, max-content));
    @screen lg {
      grid-template-columns: repeat(2, 275px);
      grid-template-rows: repeat(2, minmax(min-content, max-content));
    }
    @screen 2xl {
      grid-template-columns: repeat(2, 475px);
    }
  }
}

//---------------------------------------------
// Pricing Cards
//---------------------------------------------
.pricing-card {
  & > .grid {
    grid-template-rows: min-content min-content max-content;
  }
  .list-checkmark {
    list-style-image: url("/images/checkmark.svg");
    list-style-position: outside;
    padding-left: 20px;
    li {
      @apply font-medium;
    }
  }
}

//---------------------------------------------
// Clients section
//---------------------------------------------
.client {
  &::after {
    content: "";
    @apply absolute -z-[1] inset-0 bg-black/40;
  }
  .hover-arrow-right {
    right: 20px;
    top: 20px;
    bottom: 0;
    left: auto;
    margin: auto;
    opacity: 0;
    @screen lg {
      right: 56px;
    }
  }
  &:hover {
    background-color: #000000ab;
    .hover-arrow-right {
      opacity: 1;
      top: 0;
    }
  }
}

//---------------------------------------------
// 'We Innovate Through Creativity' Section images
//---------------------------------------------
#brewts {
  width: 35%;
}
#lightcorp-table {
  width: 17%;
}
#eko-website-mobile {
  width: 16%;
  bottom: -6%;
}
#surfaceworks {
  width: 36%;
  top: -23%;
}
#gentexBTS {
  clip-path: inset(0 45px 0 45px);
  right: -45px;
  width: 30%;
}
