@import "tailwindcss/base";
// Includes:
//  Adobe Font Definitions, downloaded font definitions
@import "./custom-base-styles.scss";

@import "tailwindcss/components";
// Navigation, tabbing, accordions...
@import "./custom-components.scss";

@import "tailwindcss/utilities";
// Letter spacing utility...
@import "./custom-utilities.scss";

// All page chunks, specifics
@import "./page-sections.scss";

@layer base {
  :root {
    --color-orange: 246, 146, 37;
    --color-red-orange: 233, 83, 41;
    --color-teal: 26, 168, 181;
    --color-magenta: 176, 46, 105;
    --color-muted: 228, 228, 228;
  }

  html {
    font-family: "Metropolis Regular", sans-serif;
  }
}
