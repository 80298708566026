/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
    font-family: 'Metropolis Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Metropolis Regular'), url('../fonts/metropolis/Metropolis-Regular.woff') format('woff');
    }

    @font-face {
    font-family: 'Metropolis Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Metropolis Medium'), url('../fonts/metropolis/Metropolis-Medium.woff') format('woff');
    }

    @font-face {
    font-family: 'Metropolis Semi Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Metropolis Semi Bold'), url('../fonts/metropolis/Metropolis-SemiBold.woff') format('woff');
    }

    @font-face {
    font-family: 'Metropolis Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Metropolis Bold'), url('../fonts/metropolis/Metropolis-Bold.woff') format('woff');
    }

    @font-face {
    font-family: 'Metropolis Extra Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Metropolis Extra Bold'), url('../fonts/metropolis/Metropolis-ExtraBold.woff') format('woff');
    }

    @font-face {
    font-family: 'Metropolis Black';
    font-style: normal;
    font-weight: normal;
    src: local('Metropolis Black'), url('../fonts/metropolis/Metropolis-Black.woff') format('woff');
    }
