//---------------------------------------------
// Scroll Revealing
//---------------------------------------------
.to-reveal {
  transition: 1s ease all;
}

// .section--hidden {
//   opacity: 0;
//   transform: translateY(4rem);
// }

// Scroll reveal delay
// @for $i from 1 through 5 {
//   .scroll-reveal-group :nth-child(#{$i}) {
//     :not(a) {
//       transition-delay: 0.15s * $i;
//     }
//   }
// }

//---------------------------------------------
// Video
//---------------------------------------------

.video-background {
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: 50vh;

  @screen lg {
    height: 100vh;
  }

  iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100vw;
    height: 50vh;
    transform: translate(-50%, -50%);

    @screen lg {
      height: 100vh;
    }

    @media (min-aspect-ratio: 16/9) {
      height: 56.25vw;
    }

    @media (max-aspect-ratio: 16/9) {
      width: 177.78vh;
    }
  }
}

//---------------------------------------------
// Accordion
//---------------------------------------------
.accordion {
  // .stacked is on the #team section accordion currently
  &.stacked {
    .item {
      &:nth-last-child(2),
      &:last-child {
        @screen lg {
          border-bottom: 1px solid black;
        }
      }
    }
  }
  &:not(.stacked) {
    .item {
      &:not(:last-child) {
        @screen lg {
          border-bottom: 1px solid black;
        }
      }
    }
  }
  .item {
    .acc-heading {
      grid-template-columns: 1fr auto;
      &.active {
        border-bottom: 0.5px solid black;
      }
    }
    .acc-content {
      margin: 0;
      overflow: hidden;
      transition: all 0.3s;
      -moz-transition: all 0.3s;
      /* Firefox 4 */
      -webkit-transition: all 0.3s;
      /* Safari and Chrome */
      -o-transition: all 0.3s;
      /* Opera */
    }
  }
}

//---------------------------------------------
// Gradient from bottom of long text content
//---------------------------------------------
.gradient-parent {
  @apply relative;
  @screen lg {
    height: 375px;
    &::after {
      z-index: 1;
      content: "";
      @apply absolute;
      @apply bottom-0 left-0 right-0;
      top: 80%;
      @apply bg-gradient-to-t from-black;
    }
    &::before {
      content: "";
      width: 100%;
      height: 2px;
      @apply bg-white;
      @apply absolute -bottom-14 left-0 right-0;
    }
  }
  & > * {
    @screen lg {
      height: 100%;
      @apply overflow-y-auto;
      -ms-overflow-style: none; /* for Internet Explorer, Edge */
      scrollbar-width: none; /* for Firefox */
      padding-bottom: 40px;
      &::-webkit-scrollbar {
        display: none; /* for Chrome, Safari, and Opera */
      }
    }
  }
}

// Labels for scrolling gradient sections

// .progress {
//   background-color: rgb(var(--color-teal));
//   height: 48px;
//   width: 0;
//   position: fixed;
//   inset: 0;
//   z-index: 0;
// }

.label {
  // height: 32px;
  // grid-template-columns: max-content;
}

.has-label-row {
  grid-template-rows: min-content min-content;
  @apply gap-y-8;
  .label {
    span {
      @apply py-4;
    }
  }
}

//---------------------------------------------
// Tabs
//---------------------------------------------
[role="tablist"] {
  @apply flex-wrap;
  @screen lg {
    @apply flex-nowrap;
  }
  [role="tab"] {
    position: relative;
    @apply duration-200;
    @apply bg-muted;
    @apply px-8 py-4;
    @apply flex-auto;

    &:hover {
      @apply bg-white bg-opacity-80;
    }
    &.active {
      @apply bg-white bg-opacity-100;
    }
    @screen lg {
      @apply max-w-max;
      &::after {
        @apply duration-200;
        content: url("/images/panel-caret.svg");
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        transform: translateY(21px);
        z-index: 10;
        opacity: 0;
      }
      &.active {
        &::after {
          opacity: 1;
        }
      }
    }
  }
}
[role="tabpanel"] {
  display: none;
}
.active-panel-container {
  display: grid;
}
[role="tabpanel"],
.active-panel-container {
  @apply bg-muted;
  @apply py-10 px-4;
  @screen lg {
    min-height: 90vh;
  }
  & > .grid {
    @apply duration-500;
    @apply gap-y-8;
    @screen sm {
      @apply container;
    }
    @screen md {
      @apply container;
    }
    @screen lg {
      grid-template-columns: 0.4fr 0.6fr;
      align-items: center;
    }
  }
  .phase-title {
    grid-auto-rows: min-content;
  }
  .phase-body {
    @apply max-w-[600px];
    p {
      @apply mb-7;
    }
    ul {
      @apply list-disc list-outside pl-5;
    }
  }
}

//---------------------------------------------
// List styling
//---------------------------------------------
ul {
  &.goals-list,
  &.requirements-list {
    @apply list-disc list-outside pl-5;
    li {
      @apply text-white font-medium text-sm lg:text-base;
    }
  }
  &#social-links {
    grid-template-columns: repeat(3, min-content);
    li {
      width: 40px;
      height: 40px;
      a {
        img {
          @apply duration-200;
        }
        &:hover {
          img {
            @apply opacity-70;
          }
        }
      }
    }
  }
}

//---------------------------------------------
// Navigation Drawer
//---------------------------------------------
nav {
  @apply bg-gradient-to-b from-black;
  #toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    * {
      @apply duration-200 ease-in-out;
    }
    &.open {
      &::before {
        transform: translateY(0) rotate(45deg);
      }
      &::after {
        transform: translateY(0) rotate(-45deg);
      }
    }
    &::before {
      @apply duration-200 ease-in-out;
      content: "";
      position: absolute;
      width: 28px;
      height: 2px;
      background-color: white;
      transform: translateY(-5px);
    }
    &::after {
      @apply duration-200 ease-in-out;
      content: "";
      position: absolute;
      width: 28px;
      height: 2px;
      background-color: white;
      transform: translateY(5px);
    }
  }
  #nav-content {
    @apply duration-300 ease-in-out;
    right: -100%;
    &.open {
      right: 0;
    }
    li {
      a {
        position: relative;
        &::after {
          @apply duration-300 ease-in-out;
          content: "";
          bottom: -5px;
          @apply absolute left-0 right-0 -bottom-1;
          @apply bg-white;
          width: 0;
          height: 2px;
        }
        &:hover {
          &::after {
            width: 100%;
          }
        }
      }
    }
  }
}
